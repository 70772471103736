export enum BenefitProgram {
  FriendsAndFamily = 'friendsAndFamily',
  Kameleon = 'kameleon',
  MilesAndMore = 'milesAndMore'
}

export interface BenefitProgramData {
  benefits: string[] | null
  description: string
  id: BenefitProgram
  logo?: string
  name: string
}
