





















import { Component, Prop, PropSync, Vue } from 'vue-property-decorator'

import { BenefitProgramJoinForm } from '../../../molecules/BenefitProgramJoinForm'
import { BenefitProgramData } from '../../../contracts/programs'
import { LoyaltyPayload } from '../../../contracts/loyalty'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({
  name: 'BenefitProgramSelectorItem',
  components: { BenefitProgramJoinForm }
})
export class BenefitProgramSelectorItem extends Vue {
  @PropSync('cardNumber', { type: String, required: false })
  protected _cardNumber?: string

  @Prop({ type: Object, required: true })
  public readonly payload!: LoyaltyPayload

  @Prop({ type: Object, required: true })
  public readonly program!: BenefitProgramData

  @Prop({ type: String, required: false })
  protected readonly selected!: string | null

  public get isSelected (): boolean {
    return this.selected === this.program.id
  }

  protected onSuccess (cardNumber: string): void {
    this._cardNumber = cardNumber
  }
}

export default BenefitProgramSelectorItem
