
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { BenefitProgramPreviewProps } from './BenefitProgramPreview.contracts'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl>
 */
@Component({ name: 'BenefitProgramPreview' })
export class BenefitProgramPreview extends Vue {
  @Prop({ type: Object, required: true })
  public readonly program!: BenefitProgramPreviewProps['program']

  @Prop({ type: Boolean, required: false, default: false })
  public readonly removable?: BenefitProgramPreviewProps['removable']

  public onRemove (): void {
    this.$emit('remove', this.program.id)
  }
}

export default BenefitProgramPreview
